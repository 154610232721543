section.hero {
  height: auto;
  padding-bottom: 30%;
  background-image: url("../../images/bg_hero.jpg");
  background-repeat: no-repeat;
  position: relative;

  .hero__heading {
    padding-top: 5rem;
  }

  .hero__subheading {
    margin-top: 2rem;
    border-left: 1px solid $dk_red;

    h6 {
      padding: 0.3rem 1.5rem;
      padding-right: 12rem;
    }
  }

  .hero__cta {
    margin-top: 2rem;
  }

  .hero__icon {
    position: absolute;
    right: 3vw;
    top: 5rem;
    width: 50vw;
  }

  .hero__icon--desktop {
    display: inline;
    position: absolute;
    right: 3vw;
    top: 5rem;
    width: 50vw;
  }

  .hero__icon--mobile {
    display: none;
  }

  .hero__icon--desktop--squares {
    animation: float 6s ease-in-out infinite !important;
    -moz-animation: float 6s ease-in-out infinite !important;
    -webkit-animation: float 6s ease-in-out infinite !important;
    position: absolute;
    right: 4vw;
    top: 5rem;
    width: 40vw;
  }
}

@media screen and (max-width: $large) {
  section.hero {
    min-height: 50rem;
    height: auto;

    .hero__heading {
      padding-top: 15vh;
    }

    .hero__subheading {
      margin-top: 2rem;
      border-left: 1px solid $dk_red;

      h6 {
        padding: 0.7rem 1.5rem;
        padding-right: 1rem;
      }
    }

    .hero__cta {
      a {
        font-size: 1rem;
      }
    }

    .hero__icon {
      position: relative !important;
      width: 80vw !important;
      left: 10%;
    }

    .hero__icon--desktop {
      display: none;
    }

    .hero__icon--mobile {
      display: inline;
      position: relative;
      left: 10%;
      width: 80vw;
    }

    .hero__icon--desktop--squares {
      display: none;
    }
  }
}

@media screen and (min-height: $large) {
  section.hero {
    min-height: auto;
    padding-bottom: 20%;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
    -moz-transform: translatey(0px);
    -webkit-transform: translatey(0px);
    -ms-transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
    -moz-transform: translatey(-20px);
    -webkit-transform: translatey(-20px);
    -ms-transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
    -moz-transform: translatey(0px);
    -webkit-transform: translatey(0px);
    -ms-transform: translatey(0px);
  }
}

@-webkit-keyframes float {
  0% {
    transform: translatey(0px);
    -moz-transform: translatey(0px);
    -webkit-transform: translatey(0px);
    -ms-transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
    -moz-transform: translatey(-20px);
    -webkit-transform: translatey(-20px);
    -ms-transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
    -moz-transform: translatey(0px);
    -webkit-transform: translatey(0px);
    -ms-transform: translatey(0px);
  }
}

@-moz-keyframes float {
  0% {
    transform: translatey(0px);
    -moz-transform: translatey(0px);
    -webkit-transform: translatey(0px);
    -ms-transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
    -moz-transform: translatey(-20px);
    -webkit-transform: translatey(-20px);
    -ms-transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
    -moz-transform: translatey(0px);
    -webkit-transform: translatey(0px);
    -ms-transform: translatey(0px);
  }
}
