section.blog--post--header {
  position: relative;
  .blog--post--header__heading {
    padding-top: 6.5rem;
  }

  .blog--post--header__subheading {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-left: 1px solid $dk_red;

    h6,
    p {
      padding: 0.1rem 1.5rem;
      padding-right: 12rem;
    }
  }

  .blog--post--header__img {
    width: 100%;
    margin-bottom: 2rem;
  }

  .tentacles {
    position: absolute;
    width: 15vw;
    right: 0%;
    top: 0%;
    z-index: -1;
  }
}

@media screen and (max-width: $large) {
  section.blog--post--header {
    .blog--post--header__subheading {
      h6,
      p {
        padding-right: 0rem;
      }
    }
  }
}
