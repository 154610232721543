@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700;800&display=swap");

h1 {
  font-weight: 800;
  font-size: 2.4vw;
}

h2 {
  font-weight: 800;
  font-size: 2vw;
}

h3 {
  font-weight: 800;
  font-size: 1.8vw;
}

h4 {
  font-weight: 600;
  font-size: 1.6vw;
  line-height: 1.6vw;
}

h5 {
  font-weight: 600;
  font-size: 1.4vw;
}

h6 {
  font-weight: 200;
  font-size: 1.2vw;
}

p {
  font-size: 1vw;
}

@media screen and (max-width: $large) {
  h1 {
    font-weight: 800;
    font-size: 6vw;
  }

  h2 {
    font-weight: 800;
    font-size: 4vw;
  }

  h3 {
    font-weight: 800;
    font-size: 3.5vw;
  }

  h4 {
    font-weight: 600;
    font-size: 3vw;
  }

  h5 {
    font-weight: 600;
    font-size: 2.5vw;
  }

  h6 {
    font-weight: 200;
    font-size: 2.5vw;
  }

  p {
    font-size: 2vw;
  }
}

@media screen and (max-width: $medium) {
  h1 {
    font-weight: 800;
    font-size: 6vw;
  }

  h2 {
    font-weight: 800;
    font-size: 5.5vw;
  }

  h3 {
    font-weight: 800;
    font-size: 5vw;
  }

  h4 {
    font-weight: 600;
    font-size: 4.5vw;
  }

  h5 {
    font-weight: 600;
    font-size: 2.5vw;
  }

  h6 {
    font-weight: 200;
    font-size: 2.5vw;
  }

  p {
    font-size: 2vw;
  }
}

@media screen and (max-width: $small) {
  h1 {
    font-weight: 800;
    font-size: 6.5vw;
  }

  h2 {
    font-weight: 800;
    font-size: 6vw;
  }

  h3 {
    font-weight: 800;
    font-size: 6vw;
  }

  h4 {
    font-weight: 600;
    font-size: 5.5vw;
  }

  h5 {
    font-weight: 600;
    font-size: 4.5vw;
  }

  h6 {
    font-weight: 200;
    font-size: 4vw;
  }

  p {
    font-size: 4vw;
  }
}

// Text for super zoom out
// @media screen and (min-width: 2500px) {
//   h1 {
//     font-weight: 800;
//     font-size: 3.5rem;
//   }

//   h2 {
//     font-weight: 800;
//     font-size: 2rem;
//   }

//   h3 {
//     font-weight: 800;
//     font-size: 2rem;
//   }

//   h4 {
//     font-weight: 600;
//     font-size: 1.5rem;
//   }

//   h5 {
//     font-weight: 600;
//     font-size: 1.5rem;
//   }

//   h6 {
//     font-weight: 200;
//     font-size: 1rem;
//   }

//   p {
//     font-size: 1rem;
//   }
// }
