section.about--intro {
  position: relative;
  padding-bottom: 10%;

  img {
    box-shadow: 1.5rem 1.5rem $dk_red;
    border-radius: 10px;
  }

  .about--intro--text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 13%;

    padding-left: 5%;
  }

  .about--intro--image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
}

@media screen and (max-width: $large) {
  section.about--intro {
    padding-top: 10%;

    img {
      box-shadow: 0.5rem 0.5rem $dk_red;
    }
  }
}
