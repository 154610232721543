section.work--hero {
  background-image: url("../../images/bg_hero.jpg");
  background-repeat: no-repeat;
  position: relative;
  padding-bottom: 5%;

  .work--hero__heading {
    padding-top: 4.5rem;
  }

  .work--hero__logo {
    width: 16vw;
    padding: 0 5%;
  }

  .work--hero__overview {
    border-left: 1px solid $dk_red;

    h6 {
      padding: 0.3rem 1.5rem;
      padding-right: 12rem;
    }
  }

  .work--hero__banner {
    width: 100%;
    padding-top: 10%;
  }

  .tentacles {
    position: absolute;
    right: 0%;
    top: 5%;
    width: 20vw;
  }
}

@media screen and (max-width: $large) {
  section.work--hero {
    .tentacles {
      display: none;
    }

    .work--hero__logo {
      width: 8rem !important;
      padding: 5%;
    }

    .work--hero__overview {
      border-left: 1px solid $dk_red;

      h6 {
        padding: 0.3rem 1.5rem;
        padding-right: 0rem;
      }
    }
  }
}
