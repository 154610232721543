section.about--team {
  min-height: 100vh;
  padding-bottom: 85%;
  position: relative;

  .about--team--text {
    text-align: left;
    flex-direction: column;
    padding: 5% 30%;
  }

  img {
    box-shadow: 1.5rem 1.5rem $dk_red;
    border-radius: 10px;
  }

  .about--team--simon {
    position: absolute;
    left: 6%;
    top: 15%;
    width: 20vw;
  }

  .about--team--jack {
    position: absolute;
    right: 10%;
    top: 30%;
    width: 37vw;
  }

  .about--team--christian {
    position: absolute;
    left: 9%;
    top: 48%;
    width: 30vw;
  }

  .about--team--artur {
    position: absolute;
    right: 15%;
    top: 60%;
    width: 23vw;
  }

  .about--team--ayub {
    position: absolute;
    left: 20%;
    top: 76%;
    width: 23vw;
  }
}

@media screen and (max-width: $large) {
  section.about--team {
    padding-bottom: 5%;

    .about--team--text {
      text-align: left;
      flex-direction: column;
      padding: 5% 0%;
    }
    img {
      box-shadow: 0.5rem 0.5rem $dk_red;
      border-radius: 10px;
      margin: 10% 0;
    }

    .about--team--simon {
      position: relative;
      left: 0%;
      width: 80%;
    }

    .about--team--jack {
      position: relative;
      left: 20%;
      width: 80%;
    }

    .about--team--christian {
      position: relative;
      left: 0%;
      width: 80%;
    }

    .about--team--artur {
      position: relative;
      left: 20%;
      right: 0;
      width: 80%;
    }

    .about--team--ayub {
      position: relative;
      left: 0%;
      width: 80%;
    }
  }
}
