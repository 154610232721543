section.error-404 {
  background-color: white;
  z-index: 999;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
