.services--card--vertical {
  text-align: center;

  .card__title {
    margin-bottom: 5%;
  }
  .card__icon {
    padding: 5% !important;
  }

  .card__subtitle {
    margin-top: 5% !important;
  }
}

.hovicon {
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  position: relative;
  text-decoration: none;
  z-index: 1;
  color: $dk_grey;
}

.hovicon:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.hovicon:before {
  speak: none;
  font-size: 48px;
  line-height: 90px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: block;
  -webkit-font-smoothing: antialiased;
}
/* Effect 1 */
.hovicon {
  background: $dk_white;
  -webkit-transition: background 0.2s, color 0.2s;
  -moz-transition: background 0.2s, color 0.2s;
  transition: background 0.2s, color 0.2s;
}
.hovicon:after {
  top: -7px;
  left: -7px;
  padding: 7px;
  box-shadow: 0 0 0 1.2px $dk_red;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -webkit-transform: scale(0.8);
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(0.8);
  opacity: 0;
}
/* Effect 1a */

.services--card--vertical:hover .hovicon {
  box-shadow: 0 0 0 1.2px $dk_red;
}

.services--card--vertical:hover .hovicon:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);

  opacity: 1;
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spinAround {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
