section.work--next--preview {
  background-color: $dk_grey_4;
  padding: 5% 0;

  .work--next__all {
    text-align: center;
    padding: 3% 0 0 0;
  }

  .work--next__all h5 {
    display: inline-block;
  }

  .work--next__all h5:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: -5%;
    position: relative;
    background: $dk_red;
    transition: width 0.2s ease 0s, left 0.2s ease 0s;
    width: 0%;
  }

  .work--next__all h5:hover:after {
    width: 110%;
    left: 0;
  }
}
