section.services--main {
  min-height: 110vh;

  .card--horizontal {
    padding: 8% 0 0 5%;
    box-shadow: 0px 14px 28px 0 rgba(0, 0, 0, 0.16);
    position: relative;
  }

  .card--link {
    color: $dk_grey; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }

  .card--link:hover .card__proceed {
    filter: invert(18%) sepia(62%) saturate(6465%) hue-rotate(344deg)
      brightness(77%) contrast(94%);

    img {
      width: 2.2rem;
    }
  }

  .card--link:hover .card__img--main {
    width: 17vw;
    padding: 1%;
    transition: all 0.2s ease;
  }

  .card--link:hover .card__icon {
    filter: invert(18%) sepia(62%) saturate(6465%) hue-rotate(344deg)
      brightness(77%) contrast(94%);
  }

  .data-management {
    margin-top: -2.5vw !important;
  }

  .card__icon {
    width: 4rem;
    padding: 0.5%;
    transition: all 1s ease;
  }

  .card__title {
    padding: 5% 0 0 0;
    display: inline-block;
  }

  .card__title:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1.5px;
    left: -5%;
    position: relative;
    background: $dk_red;
    transition: width 0.2s ease 0s, left 0.2s ease 0s;
    width: 0%;
  }

  .card__list {
    padding: 5% 0%;

    li {
      background: url(../../images/icons/list_point.svg) no-repeat left top;
      padding: 0px 0px 1px 30px;
      /* reset styles (optional): */
      list-style: none;
      margin: 0;
    }
  }

  .card__proceed {
    position: absolute;
    right: 5%;
    bottom: 7%;
    z-index: 3;
    transition: all 1s ease;

    img {
      width: 2rem;
    }
  }

  .card__proceed:hover {
    filter: invert(18%) sepia(62%) saturate(6465%) hue-rotate(344deg)
      brightness(77%) contrast(94%);
  }

  // .card__proceed:hover + .card__img--main {
  //   padding: 2%;
  // }

  .card__img--main {
    position: absolute;
    right: 0%;
    bottom: 20%;
    width: 16vw;
    padding: 3%;
    transition: all 0.1s ease-in-out;
  }
}

@media screen and (max-width: $large) {
  section.services--main {
    .card--horizontal {
      padding: 20% 0 0 5%;
      box-shadow: 0px 14px 28px 0 rgba(0, 0, 0, 0.16);
      border: 0px;
      position: relative;
      margin: 5% 0;
    }

    .card__icon {
      width: 3rem;
      padding: 1%;
    }

    .card__img--main {
      display: none;
    }
  }
}

@media screen and (min-height: $large) {
  section.services--main {
    min-height: auto;
  }
}
