section.blog--post {
  position: relative;
  .tentacles {
    position: absolute;
    width: 20vw;
    right: 0%;
    top: 0%;
    z-index: -1;
  }
}

@media screen and (max-width: $large) {
  section.blog--post {
    .tentacles {
      display: none;
    }
  }
}
