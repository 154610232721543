section.work--post {
  p {
    line-height: 180%;
    padding: 0.5% 0;
  }

  h1 {
    padding-bottom: 1%;
  }

  blockquote {
    padding: 4rem 8rem 4rem;
    position: relative;
    font-weight: 700;
    font-size: 1.5vw;
    text-align: center;
  }

  blockquote:before {
    position: absolute;
    font-family: FontAwesome;
    top: 0;
    left: 0;
    content: "\f10d";
    font-size: 5rem;
    color: rgba(0, 0, 0, 0.1);
  }

  blockquote:after {
    position: absolute;
    font-family: FontAwesome;
    bottom: 0;
    right: 0;
    content: "\f10e";
    font-size: 5rem;
    color: rgba(0, 0, 0, 0.1);
  }

  .blockquote--dash::after {
    content: "";
    top: 0rem;
    left: 50%;
    margin-left: -5rem;
    position: absolute;
    border-bottom: 3px solid #bf0024;
    height: 3px;
    width: 10rem;
  }
}

@media screen and (max-width: $large) {
  section.work--post {
    blockquote {
      padding: 10% 5%;
      position: relative;
      font-weight: 700;
      font-size: 1.2rem;
      text-align: left;
    }

    blockquote:before {
      position: absolute;
      font-family: FontAwesome;
      top: 0;
      left: 0;
      content: "\f10d";
      font-size: 4rem;
      color: rgba(0, 0, 0, 0.1);
    }

    blockquote:after {
      position: absolute;
      font-family: FontAwesome;
      bottom: 0;
      right: 0;
      content: "\f10e";
      font-size: 4rem;
      color: rgba(0, 0, 0, 0.1);
    }

    .blockquote--dash::after {
      content: "";
      top: 0rem;
      left: 50%;
      margin-left: -5rem;
      position: absolute;
      border-bottom: 3px solid #bf0024;
      height: 3px;
      width: 10rem;
    }
  }
}
