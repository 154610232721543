section.homepage--team {
  background-color: $dk_light_black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $dk_white;
  padding: 18% 0;

  .homepage--team__text {
    padding-right: 8%;
    padding-bottom: 10%;
    padding-left: 6%;
    z-index: 3;
  }

  .homepage--team__icon {
    display: inline;
    position: absolute;
    right: 0;
    width: 80vw;
  }

  .homepage--team__icon--mobile {
    display: none;
  }
}

@media screen and (max-width: $large) {
  section.homepage--team {
    min-height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 18% 0;

    .homepage--team__icon {
      display: none;
    }

    .homepage--team__text {
      padding-right: 10%;
      padding-top: 15%;
      padding-left: 5%;
    }

    .homepage--team__icon--mobile {
      display: inline;
      position: relative;
      right: 0;
      width: 100vw;
      padding: 0;
    }
  }
}

@media screen and (min-height: $large) {
  .homepage--team {
    min-height: auto !important;
    padding: 20% 0 !important;
  }
}
