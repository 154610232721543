section.blog--list--preview {
  position: relative;
  padding: 0 0 20% 0;
  .tentacles {
    position: absolute;
    width: 15vw;
    right: 8%;
    bottom: -28%;
    z-index: -1;
  }
}

@media screen and (max-width: $large) {
  section.blog--list--preview {
    .tentacles {
      display: none;
    }
  }
}
