section.looking--for {
  padding: 0% 0 30% 0;
  position: relative;
  background-color: $dk_light_black;
  color: white;

  h5 {
    padding-bottom: 5%;
    font-weight: bold;
  }

  .looking--for--header {
    text-align: center;
    padding: 15% 0;
  }

  .looking--for--text {
    display: flex;
    justify-content: center;
  }

  .looking--for--text__item {
    padding: 0 3%;
  }

  .looking--for--1 {
    position: absolute;
    top: 6%;
    right: 12%;
    width: 5vw;
  }

  .looking--for--2 {
    position: absolute;
    top: 20%;
    left: 9%;
    width: 4vw;
  }

  .looking--for--3 {
    position: absolute;
    bottom: 10%;
    right: 15%;
    width: 17vw;
  }
}

@media screen and (max-width: $large) {
  section.looking--for {
    .looking--for--header {
      text-align: left;
      padding: 15% 0 5% 0;
    }

    .looking--for--1,
    .looking--for--2,
    .looking--for--3 {
      display: none;
    }

    .looking--for--text__item {
      padding: 5% 3%;
    }

    h5 {
      padding-bottom: 1%;

      br {
        display: none;
      }
    }
  }
}
