section.homepage--contact {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 5%;
  position: relative;

  .homepage--team__text {
    padding-right: 8%;
    padding-bottom: 10%;
    padding-left: 6%;
    z-index: 3;
  }

  .homepage--team__icon {
    position: absolute;
    right: 0;
    width: 80vw;
  }

  .tentacles--left {
    position: absolute;
    width: 7vw;
    left: 20%;
    bottom: -5%;
    z-index: -1;
  }

  .tentacles--right {
    position: absolute;
    width: 7vw;
    right: 20%;
    bottom: -5%;
    z-index: -1;
  }
}

@media screen and (max-width: $large) {
  section.homepage--contact {
    height: 90vh;
    min-height: 55rem;

    .tentacles--left {
      position: absolute;
      width: 20vw;
      left: 20%;
      bottom: -5%;
      z-index: -1;
    }

    .tentacles--right {
      position: absolute;
      width: 20vw;
      right: 20%;
      bottom: -5%;
      z-index: -1;
    }
  }
}

@media screen and (min-height: $large) {
  section.homepage--contact {
    .homepage--contact {
      min-height: auto !important;
      padding: 20% 0 !important;
    }
  }
}
