section.blog--post--content {
  h3 {
    padding-top: 5%;
  }

  p {
    line-height: 180%;
    padding: 0.5% 0;
  }

  .alert-kraken {
    border-color: $dk_grey;
    background-color: $dk_grey;
    color: white;
    padding: 2%;
    margin: 2% 0;
    border-radius: 10px;
  }

  blockquote {
    padding: 2rem 4rem 2rem;
    position: relative;
    font-weight: 600;
    font-size: 1vw;
    text-align: left;
  }

  blockquote:before {
    position: absolute;
    font-family: FontAwesome;
    top: 0;
    left: 0;
    content: "\f10d";
    font-size: 2rem;
    color: rgba(0, 0, 0, 0.1);
  }

  .blog--post--share {
    padding: 2rem 0;

    img {
      width: 2rem;
      padding: 0.75% 0;
      filter: brightness(0) saturate(100%) invert(37%) sepia(0%) saturate(7028%)
        hue-rotate(100deg) brightness(83%) contrast(79%);
    }

    a {
      padding-right: 1.2rem;
    }

    img:hover {
      filter: invert(18%) sepia(62%) saturate(6465%) hue-rotate(344deg)
        brightness(77%) contrast(94%);
    }
  }
}
