.services--page {
  .services--page--info {
    position: relative;
    padding-bottom: 10%;
    display: block;
  }

  .services--page--tentacles {
    position: absolute;
    right: 0;
    top: -20%;
    width: 30vw;
  }
}

@media screen and (max-width: $large) {
  .services--page {
    .services--page--tentacles {
      display: none;
    }
  }
}
