section.about--vision {
  min-height: 100vh;
  background-color: $dk_light_black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $dk_white;
  padding-bottom: 5%;

  img {
    box-shadow: 2rem 2rem $dk_red;
    border-radius: 10px;
  }

  .about--vision__text {
    padding-right: 8%;
    padding-top: 6%;
    padding-left: 6%;
    z-index: 3;
  }

  .about--vision__icon {
    position: absolute;
    right: 10%;
    width: 35vw;
  }
}

@media screen and (max-width: $large) {
  section.about--vision {
    min-height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 10%;

    img {
      box-shadow: 0.5rem 0.5rem $dk_red;
    }
    .about--vision__text {
      padding-right: 10%;
      padding-top: 15%;
      padding-left: 5%;
    }
  }
}
