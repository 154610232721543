$dk_red: #c0162e;
$dk_grey: #59595b;
$dk_white: #ffffff;

$dk_light_black: #292929;

$dk_grey_4: #fafafa;
$dk_grey_5: #f2f2f2;

.filer-dk-red {
  filter: invert(18%) sepia(62%) saturate(6465%) hue-rotate(344deg)
    brightness(77%) contrast(94%);
}

.filter-dk-grey {
  filter: brightness(0) saturate(100%) invert(37%) sepia(0%) saturate(7028%)
    hue-rotate(100deg) brightness(83%) contrast(79%);
}

.filter-dk-white {
  filter: invert(100%) sepia(58%) saturate(0%) hue-rotate(359deg)
    brightness(106%) contrast(101%);
}

/* Kraken Red Gradient 2 */
$dk_red_gradient: linear-gradient(360deg, #c0162e 0%, #da3535 88.53%);
