section.homepage--work {
  background-color: $dk_light_black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $dk_white;
  padding: 20% 0;

  .homepage--work__text {
    padding-right: 10%;
    padding-top: 10%;
    padding-left: 5%;
  }

  .homepage--work__icon {
    position: absolute;
    left: 0;
    width: 50vw;
  }
}

@media screen and (max-width: $large) {
  section.homepage--work {
    min-height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5% 0;

    .homepage--work__icon {
      position: relative;
      left: 0;
      width: 95vw;
      padding: 10% 0;
    }

    .homepage--work__text {
      padding-right: 10%;
      padding-top: 10%;
      padding-left: 5%;
    }
  }
}

@media screen and (min-height: $large) {
  .homepage--work {
    min-height: auto !important;
    padding: 20% 0 !important;
  }
}
