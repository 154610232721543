@import "./screen-sizes";

@import "./colours";
@import "./typography";
@import "./forms";

@import "./buttons";

// Components
@import "./components/blog-post-preview.scss";
@import "./components/work-post-preview.scss";
@import "./components/service-card-vertical.scss";
@import "./components/work-post-summary-points.scss";

// Layouts
@import "./layouts/hero.scss";
@import "./layouts/navbar.scss";
@import "./layouts/homepage-services.scss";
@import "./layouts/homepage-work.scss";
@import "./layouts/homepage-team.scss";
@import "./layouts/homepage-blog.scss";
@import "./layouts/homepage-contact.scss";

@import "./layouts/about-intro.scss";
@import "./layouts/about-mission.scss";
@import "./layouts/about-vision.scss";
@import "./layouts/about-team.scss";
@import "./layouts/about-environment.scss";

@import "./layouts/blog-list-preview.scss";
@import "./layouts/blog-post-header.scss";
@import "./layouts/blog-post-content.scss";
@import "./layouts/blog-next-posts.scss";

@import "./layouts/work-hero.scss";
@import "./layouts/work-next-preview.scss";
@import "./layouts/work-post.scss";

@import "./layouts/contact-hero.scss";
@import "./layouts/contact-links.scss";
@import "./layouts/contact-map.scss";

@import "./layouts/testimonials.scss";

@import "./layouts/services-main.scss";
@import "./layouts/services-process.scss";

@import "./layouts/fit-who-are-you.scss";
@import "./layouts/fit-looking-for.scss";
@import "./layouts/fit-why-now.scss";

@import "./layouts/footer.scss";

// Pages
@import "./pages/blog-post.scss";
@import "./pages/services-pages.scss";
@import "./pages/error-404.scss";

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

body {
  font-family: "Raleway", sans-serif;
  color: $dk_grey;
  overflow-x: hidden;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1000px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1100px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1400px;
  }
}

@media (min-width: 1800px) {
  .container {
    max-width: 1600px;
  }
}

@media (min-width: 2000px) {
  .container {
    max-width: 1800px;
  }
}

@media (min-width: 2200px) {
  .container {
    max-width: 2000px;
  }
}

@media (min-width: 2500px) {
  .container {
    max-width: 2300px;
  }
}

@media (min-width: 2800px) {
  .container {
    max-width: 70vw;
  }
}

.dk--divider {
  width: 5%;
  background-color: $dk_red; /* Modern Browsers */

  color: $dk_red;
  margin: auto;
  border: 1.5px solid;
  border-color: $dk_red;
}
