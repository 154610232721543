section.who--are--you {
  padding: 10% 0 20% 0;
  position: relative;

  .who--are--you--needs {
    padding-bottom: 4%;
  }

  h5 {
    padding-bottom: 1.5%;
  }

  .who--are--you--heading {
    padding: 10% 0 5% 0;
  }

  .who--are--you--icon {
    width: 50%;
    padding-top: 40%;
    padding-left: 5%;
  }

  .who--are--you--text {
    padding-right: 10%;
  }

  .who--are--you--marketing {
    padding-left: 5%;
  }

  .who--are--you--widget {
    position: absolute;
    right: 7%;
    bottom: 10%;
    width: 7vw;
  }
}

@media screen and (max-width: $large) {
  section.who--are--you {
    .who--are--you--icon {
      display: none;
    }
    .who--are--you--needs {
      text-align: center;
    }

    .who--are--you--text,
    .who--are--you--marketing {
      padding: 5% 0% !important;
    }

    .who--are--you--widget {
      display: none;
    }
  }
}
