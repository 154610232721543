.btn {
  border-radius: 40px;
  border-color: $dk_red;
  border-width: 1px;
  min-width: 11vw;
  padding: 0.6rem 2rem;
  width: fit-content;
  font-size: 100%;
  transition: all 0.3s ease-in-out;

  &--red {
    color: $dk_red;
    border-color: $dk_red;

    &:hover {
      background-color: $dk_red;
      color: $dk_white !important;
    }
  }

  &--white {
    color: $dk_white;
    border-color: $dk_white;

    &:hover {
      background-color: $dk_white;
      color: $dk_light_black;
    }
  }
}

.colour {
  &--red {
    color: $dk_red;
  }

  &--white {
    color: $dk_white;
  }
}

@media screen and (max-width: $large) {
}

a {
  color: $dk_red;
  text-decoration: none;
}

a:hover {
  color: $dk_red;
}
