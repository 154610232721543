section.services--process {
  margin-top: 10%;
  padding-top: 5%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background-color: $dk_grey_4;

  .services--process__diagram {
    .process__diagram--item {
      position: relative;
      text-align: center;
      padding: 0% 1%;
    }

    padding: 10% 0;

    .diagram--icon {
      padding: 10% 0;
      width: 3.5vw;
    }

    .diagram--title {
      padding: 10% 0 0 0%;
    }

    .diagram--text {
      padding: 10% 3%;
    }
  }

  .process--arrow {
    position: absolute;
    right: -20%;
    top: 15%;
    width: 6vw;
  }
}

@media screen and (max-width: $large) {
  section.services--process {
    min-height: 100vh;
    padding-top: 5%;
    display: flex;
    justify-content: center;
    flex-direction: row;

    .services--process__diagram {
      padding: 15% 0;

      .diagram--icon {
        padding: 5% 0 0 0;
        width: 10vw;
      }

      .diagram--title {
        padding: 5% 0 0 0%;
      }

      .diagram--text {
        padding: 0% 15% 20% 15%;
      }
    }

    .process--arrow {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: 85% !important;
      width: 15vw;
      transform: rotate(90deg);
    }
  }
}

@media screen and (min-height: $large) {
  section.services--process {
    min-height: auto;
  }
}
