section.contact--links {
  padding: 5rem 0;

  .contact--links__email {
    position: relative;
    .contact--links__email__border {
      border-left: 1px solid $dk_red;
      position: absolute;
      top: 20%;
      bottom: 20%;
      right: 25%;
    }
  }

  .contact--links__socials {
    position: relative;

    .contact--links__socials__border {
      border-left: 1px solid $dk_red;
      position: absolute;
      top: 20%;
      bottom: 20%;
      right: 25%;
    }

    img {
      transition: all 1s ease;

      filter: brightness(0) saturate(100%) invert(37%) sepia(0%) saturate(7028%)
        hue-rotate(100deg) brightness(83%) contrast(79%);
    }

    img:hover {
      filter: invert(18%) sepia(62%) saturate(6465%) hue-rotate(344deg)
        brightness(77%) contrast(94%);
    }
  }
}

@media screen and (max-width: $large) {
  section.contact--links {
    padding: relative;

    .contact--links__email {
      .contact--links__email__border {
        border-left: none;
      }
    }

    .contact--links__socials {
      position: relative;
      padding: 3rem 1rem;

      .contact--links__socials__border {
        border-left: none;
      }
    }
  }
}
