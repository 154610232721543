div.blog--preview {
  /* [1] The container */

  .blog--preview--banner {
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
    height: auto;
    box-shadow: 1.3rem 1.2rem $dk_grey_5;
    border-radius: 8px;
    transition: all 0.3s ease;
  }

  /* [2] Transition property for smooth transformation of images */
  .blog--preview--banner img {
    transition: transform 0.5s ease;
  }

  /* [3] Finally, transforming the image when container gets hovered */
  .blog--preview--banner:hover img {
    transform: scale(1.1);
  }

  .blog--preview--details {
    color: $dk_grey !important;
  }
}

div.blog--preview:hover {
  .blog--preview--banner img {
    transform: scale(1.1);
  }

  .blog--preview--banner {
    box-shadow: 0.8rem 0.8rem $dk_red;
  }
}

@media screen and (max-width: $large) {
  div.blog--preview {
    .blog--preview--banner {
      height: auto;
    }
  }
}
