section.homepage--services {
  min-height: 110vh;
  background-color: $dk_grey_4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .homepage--services--header {
    padding: 2% 0 1% 0;
  }

  .card--vertical {
    text-align: center;
  }

  .card__title {
    padding: 10%;
  }

  .card__icon__bg {
    background-color: $dk_white;
    height: 7rem;
    width: 7rem;

    border-radius: 50%;

    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);
  }

  .card__icon {
    width: 50%;
    margin: auto;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card__subtitle {
    padding: 10%;
  }

  .tentacles {
    position: absolute;
    width: 13vw;
    left: 0;
    top: 8%;
  }

  @media screen and (max-width: $large) {
    .homepage--services--header {
      padding: 12% 0 1% 0;
    }

    .card__icon__bg {
      background-color: $dk_white;
      height: 6rem;
      width: 6rem;

      border-radius: 50%;

      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);
    }

    .card__icon {
      width: 50%;
      margin: auto;
      padding: 2%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .tentacles {
      display: none;
    }
  }
}

@media screen and (min-height: $large) {
  .homepage--services {
    min-height: auto !important;
    padding: 8% 0;
  }
}
