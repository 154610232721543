section.about--mission {
  min-height: 100vh;
  background-color: $dk_light_black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $dk_white;
  padding-top: 5%;
  padding-bottom: 5%;

  img {
    box-shadow: 2rem 2rem $dk_red;
    border-radius: 10px;
  }

  .about--mission__text {
    padding-right: 10%;
    padding-top: 10%;
    padding-left: 5%;
  }

  .about--mission__icon {
    position: absolute;
    left: 10%;
    width: 35vw;
  }
}

@media screen and (max-width: $large) {
  section.about--mission {
    min-height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    img {
      box-shadow: 0.5rem 0.5rem $dk_red;
    }

    .about--mission__text {
      padding-right: 10%;
      padding-top: 10%;
      padding-left: 5%;
    }
  }
}
