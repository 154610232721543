section.about--environment {
  padding-bottom: 23%;
  padding-top: 10%;
  .about--environment--text {
    text-align: left;
    flex-direction: column;
    padding: 5% 35%;
    position: relative;
  }

  .about--environment--icon {
    position: absolute;
    top: 0;
    left: 25%;
  }

  .about--tentacles--left {
    position: absolute;
    left: 0;
    width: 40%;
  }

  .about--tentacles--right {
    position: absolute;
    right: 0;
    width: 40%;
  }
}

@media screen and (max-width: $large) {
  section.about--environment {
    .about--environment--text {
      text-align: left;
      padding: 5% 0%;
    }

    .about--environment--icon {
      position: relative;
      top: 0;
      left: 0%;
      width: 10%;
      padding-bottom: 10%;
    }
  }
}
