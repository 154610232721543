section.why--now {
  padding: 10% 0 20% 0;
  position: relative;

  h5 {
    padding-bottom: 1.5%;
  }

  .why--now--heading {
    padding: 10% 0 5% 0;
  }

  .why--now--text {
    padding-right: 10%;
  }

  .why--now--marketing {
    padding-left: 5%;
  }

  .tentacles--services {
    position: absolute;
    z-index: -1;
    bottom: -13%;
    right: 3%;
    width: 34vw;
  }
}

@media screen and (max-width: $large) {
  section.why--now {
    padding: 0% 0 105% 0;

    .why--now--icon {
      display: none;
    }
    .why--now--needs {
      text-align: center;
    }

    .why--now--text,
    .why--now--marketing {
      padding: 5% 0% !important;
    }

    .why--now--widget {
      display: none;
    }

    .tentacles--services {
      position: absolute;
      z-index: -1;
      bottom: -5%;
      right: 25%;
      width: 60vw;
    }
  }
}
