section.contact--hero {
  min-height: 90vh;
  background-image: url("../../images/bg_hero.jpg");
  background-repeat: no-repeat;

  .hero__heading {
    padding-top: 6.5rem;
  }

  .hero__subheading {
    margin-top: 2rem;
    border-left: 1px solid $dk_red;

    h6 {
      padding: 0.3rem 1.5rem;
      padding-right: 12rem;
    }
  }

  .hero__cta {
    margin-top: 2rem;
  }

  .hero__icon {
    position: absolute;
    right: 3vw;
    top: 20vh;
    width: 50vw;
  }

  .contact--hero__form {
    padding-top: 10%;
  }
}

.contact--button {
  padding: 0.6rem 2rem;
  text-align: center;
  width: fit-content;
  border-radius: 40px;
  background: #fff;
  border: 1px solid $dk_red;
  color: $dk_red;
  letter-spacing: 1px;
  text-shadow: 0;
  cursor: pointer;
  min-width: 10vw;

  transition: all 0.25s ease;
  &:hover {
    color: white;
    background: $dk_red;
  }
  &:active {
    //letter-spacing: 2px;
    letter-spacing: 2px;
  }
  &:after {
    content: "Send";
  }
}
.onclic {
  width: 40px;
  border-color: $dk_grey;
  border-width: 3px;
  font-size: 0;
  border-left-color: $dk_red;
  animation: rotating 0.7s 0.25s linear infinite !important;
  min-width: 0vw;
  padding: 0;
  height: 40px;

  &:after {
    content: "";
  }
}

.validate {
  color: $dk_light_black;
  background: white;
  border-radius: 0;
  border-color: $dk_white;
  pointer-events: none;

  &:after {
    content: "Thanks for your message! We'll get back to you soon.";
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: $large) {
  section.contact--hero {
    .hero__subheading {
      margin-top: 2rem;
      border-left: 1px solid $dk_red;

      h6 {
        padding: 0.3rem 1.5rem;
        padding-right: 0rem;
      }
    }
  }
}

@media screen and (min-height: $large) {
  section.contact--hero {
    min-height: auto;
  }
}
