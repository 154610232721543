div.work--preview {
  position: relative;
  cursor: pointer;

  /* [1] The container */
  .work--preview--banner {
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
    height: 100%;
    width: 100%;
    box-shadow: 1.5rem 1.5rem $dk_grey_5;
    border-radius: 8px;
    transition: all 0.3s ease;
  }

  /* [2] Transition property for smooth transformation of images */
  .work--preview--banner img {
    transition: transform 0.5s ease;
  }

  /* [3] Finally, transforming the image when container gets hovered */
  .work--preview--banner:hover img {
    transform: scale(1.1);
  }

  .work--preview--text {
    z-index: 3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
}

.work--preview--banner:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0.6;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.work--preview--banner:hover:after {
  opacity: 0.1;
}

div.work--preview:hover {
  .work--preview--banner img {
    transform: scale(1.1);
  }

  .work--preview--banner {
    box-shadow: 1rem 1rem $dk_red;
  }
}
