input {
  width: 100%;
  background-color: $dk_grey_5;
  padding: 4% 5%;
  margin: 2% 0;
  border: none;
  border-radius: 20px;
  transition: all 0.1s ease-in-out;
}

textarea {
  width: 100%;
  height: 200px;
  margin: 2% 0;
  padding: 4% 5%;

  border: none;
  background-color: $dk_grey_5;
  border-radius: 20px;
}

textarea:focus {
  outline: none;
}

input:focus {
  outline: none;
}
