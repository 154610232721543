section.homepage--blog {
  min-height: 110vh;
  background-color: $dk_white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .homepage--blog__icon {
    width: 100%;
  }

  .homepage--blog--header {
    padding: 8% 0 1% 0;
  }

  .homepage--blog--all h5 {
    display: inline-block;
  }

  .homepage--blog--all h5:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: -5%;
    position: relative;
    background: $dk_red;
    transition: width 0.2s ease 0s, left 0.2s ease 0s;
    width: 0%;
  }

  .homepage--blog--all h5:hover:after {
    width: 110%;
    left: 0;
  }
}

@media screen and (min-height: $large) {
  .homepage--blog {
    min-height: auto !important;
    padding: 5% 0 !important;
  }
}
