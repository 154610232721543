section.testimonials {
  background-color: $dk_grey_4;
  overflow: hidden;
  position: relative;

  .testimonials--header {
    padding: 10% 0 2% 0;
  }

  .testimonial--container {
    background-color: $dk_white;
    padding: 5% 4% !important;
    margin: 0 5%;
    box-shadow: 20px 20px $dk_grey;
    border-radius: 8px;
  }

  .testimonial--container__icon {
    font-size: 8vw;
    color: $dk_red;
    display: flex;
    align-items: center;
  }

  .testimonial--container__quote {
    font-weight: 700;
    display: flex;
    align-items: center;
    font-size: 1.5vw;
  }

  .testimonial--container__logo {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5%;
  }

  .testimonial--container__author {
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-top: 4%;
  }

  .testimonials--controls {
    display: flex;
    justify-content: center;
  }

  .testimonials--prev {
    position: absolute;
    bottom: 4%;
    left: 48%;
  }
  .testimonials--next {
    position: absolute;
    bottom: 4%;
    left: 52%;
  }

  .testimonials--prev,
  .testimonials--next {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    padding: 1%;
    filter: brightness(0) saturate(100%) invert(37%) sepia(0%) saturate(7028%)
      hue-rotate(100deg) brightness(83%) contrast(79%);
    transition: all 1s ease-in-out;
  }

  .testimonials--prev:hover,
  .testimonials--next:hover {
    filter: invert(18%) sepia(62%) saturate(6465%) hue-rotate(344deg)
      brightness(77%) contrast(94%);
  }

  .testimonials--prev img,
  .testimonials--next img {
    width: 110%;
  }

  .tentacles {
    position: absolute;
    width: 8vw;
    left: 0;
    top: 40%;
  }
}

@media screen and (max-width: $large) {
  .testimonial--container__icon {
    font-size: 8vw;
    padding: 5%;
  }

  .testimonial--container__quote {
    font-size: 5vw !important;
    padding-left: 3%;
  }

  .testimonial--container {
    box-shadow: 10px 10px $dk_grey !important;
  }

  .tentacles {
    display: none;
  }

  .testimonials--prev,
  .testimonials--next {
    position: relative !important;
    bottom: auto !important;
    right: auto !important;
    left: auto !important;
  }
}

@media screen and (min-height: $large) {
  section.testimonials {
    min-height: auto !important;
    padding: 0% 0 !important;
  }
}
